import "./HostelDetails.scss";

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { NativeSelect } from "@mantine/core";

import deleteIcon from "../../assets/images/Delete.png";

import CustomNumberInput from "../../components/CustomNumberInput/CustomNumberInput";
import Button from "../../components/Button/Button";
import DropDown from "../../components/DropDown/DropDown";
import Modal from "../../components/Modal/Modal";
import Loader from "../../components/Loader/Loader";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
import { useSelector } from "react-redux";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const HostelDetails = () => {
  //RBAC Code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Management")
    ?.dropdowns.find((dd) => dd.name === "Hostels")?.permissions;
  //RBAC Code block ends.

  const { id } = useParams();
  const [hostelDetails, setHostelDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isAddFloorOpen, setIsAddFloorOpen] = useState(false);
  const [isAddRoomOpen, setIsAddRoomOpen] = useState(false);
  const [isDeleteHostelOpen, setIsDeleteHostelOpen] = useState(false);
  const [addRoomFloorId, setAddRoomFloorId] = useState(null);
  const [addRoomFloorNumber, setAddRoomFloorNumber] = useState(null);

  const [deleteId, setDeleteId] = useState(false);

  const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
  let accessToken = tokens.accessToken;

  const navigate = useNavigate();

  useEffect(() => {
    loadHostelDetails();
  }, [id]);

  const fetchHostelDetails = async (hostelId, accessToken) => {
    try {
      const response = await axios.get(
        // `http://localhost:8000/api/hostels/${hostelId}`,
        `${BE_URL}/api/hostels/${hostelId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Token might have expired
        return null;
      }
      throw error;
    }
  };

  const loadHostelDetails = async () => {
    setLoading(true);
    setError("");

    let details = await fetchHostelDetails(id, accessToken);
    if (!details) {
      // Try refreshing the token
      accessToken = await refreshToken();
      if (accessToken) {
        details = await fetchHostelDetails(id, accessToken);
      }
    }

    if (details) {
      setHostelDetails(details);
    } else {
      setError("Failed to fetch hostel details. Please try again.");
    }

    setLoading(false);
  };
  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post("${BE_URL}/refreshToken", {
        token: tokens.refreshToken,
      });

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  // Inside HostelDetails component

  const handleAddFloors = async (newFloors) => {
    setLoading(true);
    setError("");

    try {
      let response = await addFloors(id, newFloors, accessToken);
      if (!response) {
        // Token might have expired, try refreshing
        accessToken = await refreshToken();
        if (accessToken) {
          response = await addFloors(id, newFloors, accessToken);
        }
      }

      if (response) {
        // setHostelDetails({
        //   ...hostelDetails,
        //   floors: [...hostelDetails.floors, ...response.data],
        // });
        loadHostelDetails();
        setLoading(false);
        setIsAddFloorOpen(false);
      } else {
        setError("Failed to add floors. Please try again.");
        setLoading(false);
        setIsAddFloorOpen(false);
      }
    } catch (error) {
      console.log("Error is ", error);
      if (error.response && error.response.status === 403) {
        alert("Floor Number Already exist.");
      }
      setLoading(false);
      console.error("Error adding floors:", error);
      setError(error.message || "An error occurred while adding floors.");
    } finally {
      setLoading(false);
      //   setIsAddFloorOpen(false);
    }
  };

  const addFloors = async (hostelId, newFloors, accessToken) => {
    try {
      const response = await axios.post(
        // `http://localhost:8000/api/hostels/${hostelId}/addFloors`,
        `${BE_URL}/api/hostels/${hostelId}/addFloors`,
        newFloors,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        return null; // Token expired
      }
      throw error;
    }
  };

  const addRoomHelper = (floor_id, floor_number) => {
    window.scrollTo(0, 0);
    setAddRoomFloorId(floor_id);
    console.log("Floor number is ", floor_number);
    setAddRoomFloorNumber(floor_number);
    setIsAddRoomOpen(true);
  };

  // Inside HostelDetails component

  const handleAddRoom = async (total_beds, room_number) => {
    if (!total_beds || !room_number) {
      alert("Please fill in Room Number and number of beds.");
      return;
    }
    setLoading(true);
    setError("");

    try {
      let response = await addRoom(
        addRoomFloorId,
        total_beds,
        room_number,
        accessToken
      );
      if (!response) {
        // Token might have expired, try refreshing
        accessToken = await refreshToken();
        if (accessToken) {
          response = await addRoom(
            addRoomFloorId,
            total_beds,
            room_number,
            accessToken
          );
        }
      }

      if (response) {
        console.log(response);
        // Refresh or update hostel details to reflect the new room
        loadHostelDetails();
        setLoading(false);
        setIsAddRoomOpen(false);
      } else {
        setError("Failed to add room. Please try again.");
      }
    } catch (error) {
      if (error.response.status === 403) {
        alert("Room Number already exists");
      }
      setError("Room Number already exists");
      setLoading(false);
      console.error("Error adding room:", error);
      setError(error.message || "An error occurred while adding the room.");
    } finally {
      //   setLoading(false);
      //   setIsAddRoomOpen(false);
    }
  };

  const addRoom = async (floorId, total_beds, room_number, accessToken) => {
    try {
      const response = await axios.post(
        // `http://localhost:8000/api/hostels/${floorId}/addRoom`,
        `${BE_URL}/api/hostels/${floorId}/addRoom`,
        {
          total_beds,
          hostel_id: id,
          floor_number: addRoomFloorNumber,
          room_number,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        return null; // Token expired
      }
      throw error;
    }
  };

  const handleDelete = async (id, type) => {
    setLoading(true);
    setError("");
    window.scrollTo(0, 0);

    try {
      let response = await deleteItem(id, type, accessToken);
      if (!response) {
        accessToken = await refreshToken();
        if (accessToken) {
          response = await deleteItem(id, type, accessToken);
        }
      }

      if (response) {
        // Update state or re-fetch data to reflect changes

        if (type === "hostels") {
          alert("Hostel deleted Successfully");
          return navigate("/admin/hostels");
        }
        loadHostelDetails();
      } else {
        setError("Failed to delete room. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting room:", error);
      setError(error.message || "An error occurred while deleting the room.");
    } finally {
      setLoading(false);
    }
  };

  const deleteItem = async (id, type, accessToken) => {
    try {
      const response = await axios.delete(
        // `http://localhost:8000/api/${type}/${id}`,
        `${BE_URL}/api/${type}/${id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        return null; // Token expired
      }
      throw error;
    }
  };

  return (
    <div className="hosteld">
      {loading && <Loader />}
      <Modal isOpen={isAddFloorOpen} onClose={() => setIsAddFloorOpen(false)}>
        <AddFloor handleAddFloors={handleAddFloors} permissions={permissions} />
      </Modal>
      <Modal isOpen={isAddRoomOpen} onClose={() => setIsAddRoomOpen(false)}>
        <AddRoom
          permissions={permissions}
          handleAddRoom={(total_beds, room_number) =>
            handleAddRoom(total_beds, room_number)
          }
        />
      </Modal>
      <Modal
        isOpen={isDeleteHostelOpen}
        onClose={() => setIsDeleteHostelOpen(false)}
      >
        <ConfirmationModal
          onConfirm={() => handleDelete(id, "hostels")}
          onCancel={() => setIsDeleteHostelOpen(false)}
          ques={`Are you sure you want to delete this Hostel?`}
        />
      </Modal>

      <div className="hosteld-header">
        <div
          className="asd-cta-back"
          onClick={() => navigate("/admin/hostels")}
        >
          &larr; Back
        </div>
        <h1>
          {hostelDetails ? hostelDetails.hostel.hostel_name : "Loading..."}
        </h1>
        {permissions?.can_delete && (
          <img
            src={deleteIcon}
            alt=""
            onClick={() => setIsDeleteHostelOpen(true)}
          />
        )}
      </div>
      <div className="hosteld-info">
        <div className="hosteld-info-cards">
          <div className="hosteld-info-card">
            <div className="hosteld-info-card-title">Total Capacity </div>
            <div className="hosteld-info-card-number">
              {hostelDetails &&
                parseInt(hostelDetails.occupancy) +
                  parseInt(hostelDetails.vacency)}
            </div>
          </div>
          <div className="hosteld-info-card">
            <div className="hosteld-info-card-title">Vacent Beds</div>
            <div className="hosteld-info-card-number">
              {hostelDetails && parseInt(hostelDetails.vacency)}
            </div>
          </div>
          <div className="hosteld-info-card">
            <div className="hosteld-info-card-title">Occupied Beds </div>
            <div className="hosteld-info-card-number">
              {hostelDetails && parseInt(hostelDetails.occupancy)}
            </div>
          </div>
        </div>
        {permissions?.can_create && (
          <Button
            text={`Add Floors`}
            onClick={() => {
              window.scrollTo(0, 0);
              setIsAddFloorOpen(true);
            }}
          />
        )}
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="hosteld-floors">
          {hostelDetails &&
            hostelDetails.floors.map((floor, index) => (
              <FloorPlan
                permissions={permissions}
                key={index}
                floor={floor}
                accessToken={accessToken}
                deleteId={deleteId}
                setDeleteId={setDeleteId}
                addRoomHelper={(floor_id, floor_number) =>
                  addRoomHelper(floor_id, floor_number)
                }
                handleDeleteRoom={() => handleDelete(deleteId, "rooms")}
                handleDeleteFloor={(roomId) => handleDelete(roomId, "floors")}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default HostelDetails;

const FloorPlan = ({
  floor,
  deleteId,
  setDeleteId,
  addRoomHelper,
  handleDeleteRoom,
  handleDeleteFloor,
  permissions,
  accessToken,
}) => {
  const [isDeleteFloorOpen, setIsDeleteFloorOpen] = useState(false);
  const [isDeleteRoomOpen, setIsDeleteRoomOpen] = useState(false);
  const [isHostelDetailsOpen, setisHostelDetailsOpen] = useState(false);

  const [selectedBedId, setselectedBedId] = useState(null);

  const handleBedClicked = (id) => {
    setselectedBedId(id);
    setisHostelDetailsOpen(true);
  };

  return (
    <div className="fplan">
      <Modal
        isOpen={isDeleteFloorOpen}
        onClose={() => setIsDeleteFloorOpen(false)}
      >
        <ConfirmationModal
          onConfirm={() => handleDeleteFloor(floor.id)}
          onCancel={() => setIsDeleteFloorOpen(false)}
          ques={`Are you sure you want to delete this floor?`}
        />
      </Modal>
      <Modal
        isOpen={isHostelDetailsOpen}
        onClose={() => setisHostelDetailsOpen(false)}
      >
        <BedDetails bedId={selectedBedId} accessToken={accessToken} />
      </Modal>
      <div className="fplan-header">
        <h2>Floor {floor.floor_number}</h2>
        {permissions?.can_delete && (
          <img
            src={deleteIcon}
            alt=""
            onClick={() => setIsDeleteFloorOpen(true)}
          />
        )}
      </div>
      <div className="fplan-info">
        <div className="fplan-info-card">
          <div className="fplan-info-card-title">Total Rooms </div>
          <div className="fplan-info-card-number">{floor.rooms.length}</div>
        </div>
        {permissions?.can_create && (
          <Button
            text={`Add Room`}
            onClick={() => addRoomHelper(floor.id, floor.floor_number)}
          />
        )}
      </div>
      <div className="fplan-rooms">
        {floor.rooms.map((room, index) => (
          <div key={index} className="room">
            <Modal
              isOpen={isDeleteRoomOpen}
              onClose={() => setIsDeleteRoomOpen(false)}
            >
              <ConfirmationModal
                onConfirm={() => handleDeleteRoom(room.id)}
                onCancel={() => setIsDeleteRoomOpen(false)}
                ques={`Are you sure you want to delete this Room?`}
              />
            </Modal>
            <div className="room-header">
              <h3>Room {room.room_number}</h3>
              {permissions?.can_delete && (
                <img
                  src={deleteIcon}
                  alt=""
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setDeleteId(room.id);
                    setIsDeleteRoomOpen(true);
                  }}
                />
              )}
            </div>
            <div className="beds">
              {room.beds.map((bed, idx) => (
                <div
                  key={idx}
                  className={`bed ${bed.student_id ? "occupied" : "vacant"}`}
                  onClick={() => handleBedClicked(bed.id)}
                >
                  Bed {bed.bed_number}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const AddFloor = ({ handleAddFloors, permissions }) => {
  const [floorNumber, setfloorNumber] = useState(null);

  const handleSubmit = () => {
    if (!floorNumber) {
      alert("Please fill in a floor number");
      return;
    }
    const newFloorsData = { floor_number: floorNumber };
    handleAddFloors(newFloorsData);
  };

  return (
    <div className="addfloor">
      <div className="addfloor-title">Add Floor</div>
      <CustomNumberInput
        label={`Floor Number`}
        placeholder="Floor Number"
        value={floorNumber}
        onChange={setfloorNumber}
      />
      {permissions?.can_create && (
        <Button text="Add Floor" onClick={handleSubmit} />
      )}
    </div>
  );
};

const AddRoom = ({ handleAddRoom, permissions }) => {
  const [roomNumber, setRoomNumber] = useState(null);
  const [value, setValue] = useState(null);
  //   const [value, setValue] = (useState < string) | (null > "");

  const roomTypes = [
    {
      label: "4 Seater",
      value: 4,
    },
    {
      label: "3 Seater",
      value: 3,
    },
    {
      label: "2 Seater",
      value: 2,
    },
    {
      label: "1 Seater",
      value: 1,
    },
  ];
  const roomSize = [4, 3, 2, 1];

  return (
    <div className="addrooms">
      <div className="addrooms-title">Add a Room.</div>
      <CustomNumberInput
        placeholder="Room Number"
        value={roomNumber}
        label={`Room Number`}
        onChange={setRoomNumber}
      />
      <CustomNumberInput
        placeholder="Number of Beds"
        value={value}
        label={`Number of Beds`}
        onChange={setValue}
      />

      {permissions?.can_create && (
        <Button
          text="Add Room"
          onClick={() => handleAddRoom(value, roomNumber)}
        />
      )}
    </div>
  );
};

const BedDetails = ({ bedId, accessToken }) => {
  const [studentDetails, setStudentDetails] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    const fetchBedDetails = async () => {
      setisLoading(true);
      try {
        const response = await axios.get(
          // `http://localhost:8000/api/hostels/${hostelId}`,
          `${BE_URL}/api/bedDetails/${bedId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        setStudentDetails(response.data);
        setisLoading(false);
      } catch (error) {
        console.error("Failed to fetch bed details:", error);
        setisLoading(false);
      }
    };

    if (bedId) {
      fetchBedDetails();
    }
  }, []);

  return (
    <div className="bedDetails">
      {isLoading && <Loader />}
      {studentDetails && studentDetails.roll_no ? (
        <div>
          <h3>Student Details</h3>
          <p>
            <strong>Name:</strong> {studentDetails.first_name}{" "}
            {studentDetails.middle_name} {studentDetails.last_name}
          </p>
          <p>
            <strong>Roll Number:</strong> {studentDetails.roll_no}
          </p>
          <p>
            <strong>WhatsApp:</strong> {studentDetails.whatsapp}
          </p>
        </div>
      ) : (
        <p>No student details available for this bed.</p>
      )}
    </div>
  );
};
