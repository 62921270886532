import React, { useState, useEffect } from "react";
import "./AnnouncementsDisplay.scss"; // Ensure you have SCSS set up in your project
import axios from "axios";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const AnnouncementsDisplay = () => {
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    // Fetch announcements from your API
    const fetchAnnouncements = async () => {
      try {
        const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
        // const response = await axios.get("http://localhost:8000/api/documents", {
        const response = await axios.get(
          `${BE_URL}/api/general/announcements`,
          {
            headers: { Authorization: `Bearer ${tokens.accessToken}` },
          }
        );

        setAnnouncements(response.data);
      } catch (error) {
        console.error("Failed to fetch announcements:", error);
      }
    };

    fetchAnnouncements();
  }, []);

  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    const formattedTime = date.toLocaleTimeString(); // e.g., "12:32:35 AM"
    return `${formattedDate} ${formattedTime}`;
  };
  return (
    <div className="announcements-display">
      {announcements.length > 0 ? (
        announcements.map((announcement) => (
          <div key={announcement.id} className="announcement-card">
            <div className="announcement-header">
              <h2 className="announcement-title">{announcement.subject}</h2>
              <span className="announcement-date">
                {formatDateAndTime(announcement.created_at)}
              </span>
            </div>
            <p className="announcement-content">{announcement.body}</p>
          </div>
        ))
      ) : (
        <div className="no-announcements">No announcements available.</div>
      )}
    </div>
  );
};

export default AnnouncementsDisplay;
