import React, { useEffect, useState } from "react";
import axios from "axios";
import "./LogsPage.scss";

const BE_URL = "https://server.classerp.in";

const LogsPage = () => {
  const [logs, setLogs] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalPages: 0,
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedLog, setSelectedLog] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    const formattedTime = date.toLocaleTimeString(); // e.g., "12:32:35 AM"
    return `${formattedDate} ${formattedTime}`;
  };

  const fetchLogs = async (page = 1, limit = 10, searchQuery = "") => {
    setLoading(true);

    try {
      const response = await axios.get(`${BE_URL}/api/logs`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ERPTokens")}`,
        },
        params: { page, limit, search: searchQuery },
      });

      setLogs(response.data.data);
      setPagination(response.data.pagination);
    } catch (error) {
      console.error("Error fetching logs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    fetchLogs(1, pagination.limit, search);
  };

  const handlePageChange = (newPage) => {
    fetchLogs(newPage, pagination.limit, search);
  };

  return (
    <div className="logs-page">
      <header className="logs-page__header">
        <h1>Payment Logs</h1>
      </header>

      <div className="logs-page__controls">
        <form className="logs-page__search" onSubmit={handleSearch}>
          <input
            type="text"
            placeholder="Search by Order ID, Payment ID, or Status"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button type="submit">Search</button>
        </form>
      </div>

      {loading ? (
        <div className="logs-page__loading">Loading logs...</div>
      ) : logs.length > 0 ? (
        <div className="logs-page__table-wrapper">
          <table className="logs-page__table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Timestamp</th>
                <th>Event Type</th>
                <th>Order ID</th>
                <th>Payment ID</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log) => (
                <tr
                  key={log.id}
                  onClick={() => {
                    setSelectedLog(log);
                    setIsModalOpen(true);
                  }}
                  className="logs-page__table-row"
                >
                  <td>{log.id}</td>
                  <td>{formatDateAndTime(log.created_at)}</td>
                  <td>{log.event_type}</td>
                  <td>{log.order_id}</td>
                  <td>{log.payment_id}</td>
                  <td>
                    {log.amount ? "₹" : ""}
                    {log.amount}
                  </td>
                  <td
                    className={`logs-page__status logs-page__status--${log.status}`}
                  >
                    {log.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="logs-page__empty">No logs found.</div>
      )}

      <div className="logs-page__pagination">
        <button
          disabled={pagination.page === 1}
          onClick={() => handlePageChange(pagination.page - 1)}
        >
          Previous
        </button>
        <span>
          Page {pagination.page} of {pagination.totalPages}
        </span>
        <button
          disabled={pagination.page === pagination.totalPages}
          onClick={() => handlePageChange(pagination.page + 1)}
        >
          Next
        </button>
      </div>

      {isModalOpen && selectedLog && (
        <div className="logs-page__modal">
          <div className="logs-page__modal-content">
            <h2>Log Details</h2>
            <div>
              <strong>ID:</strong> {selectedLog.id}
            </div>
            <div>
              <strong>Timestamp:</strong>{" "}
              {new Date(selectedLog.timestamp).toLocaleString()}
            </div>
            <div>
              <strong>Order ID:</strong> {selectedLog.order_id}
            </div>
            <div>
              <strong>Payment ID:</strong> {selectedLog.payment_id}
            </div>
            <div>
              <strong>Amount:</strong> ₹{selectedLog.amount}
            </div>
            <div>
              <strong>Status:</strong> {selectedLog.status}
            </div>
            <div>
              <strong>Payload:</strong>
              <pre className="logs-page__modal-pre">
                {JSON.stringify(selectedLog.payload, null, 2)}
              </pre>
            </div>
            <div>
              <strong>Response Body:</strong>
              <pre className="logs-page__modal-pre">
                {JSON.stringify(selectedLog.response_body, null, 2)}
              </pre>
            </div>
            <button
              onClick={() => setIsModalOpen(false)}
              className="logs-page__modal-close"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LogsPage;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import "./LogsPage.scss";

// // const BE_URL = "http://localhost:8000";
// const BE_URL = "https://server.classerp.in";

// const LogsPage = () => {
//   const [logs, setLogs] = useState([]);
//   const [pagination, setPagination] = useState({
//     page: 1,
//     limit: 10,
//     totalPages: 0,
//     total: 0,
//   });
//   const [loading, setLoading] = useState(false);
//   const [search, setSearch] = useState("");

//   const fetchLogs = async (page = 1, limit = 10, searchQuery = "") => {
//     setLoading(true);

//     try {
//       const response = await axios.get(`${BE_URL}/api/payment/logs`, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("ERPTokens")}`,
//         },
//         params: {
//           page,
//           limit,
//           search: searchQuery,
//         },
//       });

//       setLogs(response.data.data);
//       setPagination(response.data.pagination);
//     } catch (error) {
//       console.error("Error fetching logs:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchLogs();
//   }, []);

//   const handleSearch = (e) => {
//     e.preventDefault();
//     fetchLogs(1, pagination.limit, search);
//   };

//   const handlePageChange = (newPage) => {
//     fetchLogs(newPage, pagination.limit, search);
//   };

//   return (
//     <div className="logs-page">
//       <header className="logs-page__header">
//         <h1>Payment Logs</h1>
//       </header>

//       <div className="logs-page__controls">
//         <form className="logs-page__search" onSubmit={handleSearch}>
//           <input
//             type="text"
//             placeholder="Search by Order ID, Payment ID, or Status"
//             value={search}
//             onChange={(e) => setSearch(e.target.value)}
//           />
//           <button type="submit">Search</button>
//         </form>
//       </div>

//       {loading ? (
//         <div className="logs-page__loading">Loading logs...</div>
//       ) : logs.length > 0 ? (
//         <div className="logs-page__table-wrapper">
//           <table className="logs-page__table">
//             <thead>
//               <tr>
//                 <th>ID</th>
//                 <th>Timestamp</th>
//                 <th>Order ID</th>
//                 <th>Payment ID</th>
//                 <th>Amount</th>
//                 <th>Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {logs.map((log) => (
//                 <tr key={log.id}>
//                   <td>{log.id}</td>
//                   <td>{new Date(log.timestamp).toLocaleString()}</td>
//                   <td>{log.order_id}</td>
//                   <td>{log.payment_id}</td>
//                   <td>₹{log.amount}</td>
//                   <td
//                     className={`logs-page__status logs-page__status--${log.status}`}
//                   >
//                     {log.status}
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       ) : (
//         <div className="logs-page__empty">No logs found.</div>
//       )}

//       <div className="logs-page__pagination">
//         <button
//           disabled={pagination.page === 1}
//           onClick={() => handlePageChange(pagination.page - 1)}
//         >
//           Previous
//         </button>
//         <span>
//           Page {pagination.page} of {pagination.totalPages}
//         </span>
//         <button
//           disabled={pagination.page === pagination.totalPages}
//           onClick={() => handlePageChange(pagination.page + 1)}
//         >
//           Next
//         </button>
//       </div>
//     </div>
//   );
// };

// export default LogsPage;
