import React, { useEffect, useState } from "react";
import axios from "axios";
import "./FeesInformation.scss";
import { Input, SelectPicker } from "rsuite";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
import DropDown from "../../components/DropDown/DropDown";
import Button from "../../components/Button/Button";
import { utils, writeFile } from "xlsx";
import Loader from "../../components/Loader/Loader";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const FeesInformation = () => {
  const [installments, setInstallments] = useState([]);
  const [filteredInstallments, setfilteredInstallments] = useState([]);
  const [overdueSummary, setOverdueSummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedInstallments, setSelectedInstallments] = useState([]);
  const [emailContent, setEmailContent] = useState("");
  const [filters, setFilters] = useState({
    paymentType: "",
    dateRange: null,
    session: "",
    installment: "",
    status: "",
  });
  const [sessions, setSessions] = useState([
    "2024-2025",
    "2025-2026",
    "2026-2027",
  ]); // Sessions list
  const [installmentOptions, setInstallmentOptions] = useState([]); // Installments list
  const tokens = JSON.parse(localStorage.getItem("ERPTokens"));

  // Fetch overdue installment summary
  const fetchOverdueSummary = async () => {
    try {
      const response = await axios.get(
        `${BE_URL}/api/feeDetails/installments/overdue`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      setOverdueSummary(response.data);
    } catch (error) {
      console.error("Failed to fetch overdue summary", error);
    }
  };

  // Fetch filtered installments based on filters
  const fetchInstallments = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BE_URL}/api/feeDetails/installments`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
          params: filters, // Add filters to request
        }
      );
      setInstallments(response.data);
      setfilteredInstallments(response.data);
    } catch (error) {
      console.error("Failed to fetch installments", error);
    }
    setLoading(false);
  };

  // Fetch installment options for dropdown
  const fetchInstallmentOptions = async () => {
    try {
      const response = await axios.get(
        `${BE_URL}/api/general/getAllInstallments/`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      setInstallmentOptions(response.data);
    } catch (error) {
      console.error("Failed to fetch installment options", error);
    }
  };

  useEffect(() => {
    fetchInstallmentOptions();
    fetchOverdueSummary();
    fetchInstallments();
  }, [filters]);

  const handleFilterChange = (field, value) => {
    console.log("field is ", field);
    console.log("value is ", value);
    setFilters({
      ...filters,
      [field]: value,
    });
  };

  const handleInstallmentSelect = (id) => {
    setSelectedInstallments((prev) =>
      prev.includes(id) ? prev.filter((instId) => instId !== id) : [...prev, id]
    );
  };

  const handleSendEmails = async () => {
    try {
      await axios.post(
        `${BE_URL}/api/feeDetails/installments/send-emails`,
        {
          installmentIds: selectedInstallments,
          emailContent,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      alert("Emails sent successfully!");
    } catch (error) {
      console.error("Failed to send emails", error);
      alert("Failed to send emails, please try again.");
    }
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();

    const newInstallments = installments.filter((installment) => {
      return (
        installment.name.toLowerCase().includes(searchValue) ||
        installment.whatsapp.includes(searchValue) ||
        installment.student_roll_no.toLowerCase().includes(searchValue)
      );
    });

    setfilteredInstallments(newInstallments);
  };

  const downloadExcel = () => {
    setLoading(true);
    const ws = utils.json_to_sheet(filteredInstallments);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "FeesInformation");

    // Generate Excel file and download
    writeFile(wb, "FeesInformation.xlsx");
    setLoading(false);
  };

  return (
    <div className="feesinfo">
      {loading && <Loader />}
      <div className="feesinfo-header">
        <div className="feesinfo-header-title">Fees Information</div>
      </div>

      <div className="feesinfo-content">
        <div className="feesinfo-content-overdue">
          <div className="feesinfo-content-overdue-title">Overdues</div>
          <div className="feesinfo-content-overdue-cards">
            {overdueSummary.map((data, index) => (
              <div key={index} className="feesinfo-content-overdue-cards-card">
                <h3>{data.account ? data.account : "Uncategorized"}</h3>
                <p>{data.overdue_count} overdue installments</p>
                <p>Total Pending: Rs. {data.total_pending}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="feesinfo-installments">
          {/* Filters */}
          <div className="feesinfo-installments-filters">
            <div className="feesinfo-installments-filters-row1">
              <div className="feesinfo-installments-filters-row1-search">
                {/* <div className="elist-filters-label">
                  
                </div> */}
                <Input
                  placeholder="Search by name, roll no or whatsapp..."
                  onChange={(value) =>
                    handleSearchChange({
                      target: { value: value.toLowerCase() },
                    })
                  }
                  className="search-bar"
                />
              </div>
              <div className="feesinfo-installments-filters-row1-excel">
                <Button
                  type={`primary`}
                  text={`Excel`}
                  onClick={downloadExcel}
                />
              </div>
            </div>
            <div className="feesinfo-installments-filters-row2">
              <div className="feesinfo-installments-filters-row2-inst">
                <CustomDropDown
                  data={installmentOptions}
                  onChange={(e) => handleFilterChange("installment", e)}
                  value={filters.installment}
                  cleanable={true}
                  searchable={true}
                  label="Select Installment"
                />
              </div>
              <div className="feesinfo-installments-filters-row2-session">
                <CustomDropDown
                  data={[
                    {
                      id: "2024-2025",
                      name: "2024-2025",
                    },
                    {
                      id: "2025-2026",
                      name: "2025-2026",
                    },
                    {
                      id: "2026-2027",
                      name: "2026-2027",
                    },
                  ]}
                  onChange={(e) => handleFilterChange("session", e)}
                  value={filters.session}
                  cleanable={true}
                  searchable={false}
                  label="Select Session"
                />
              </div>

              <div className="feesinfo-installments-filters-row2-status">
                <CustomDropDown
                  data={[
                    {
                      id: "Paid",
                      name: "Paid",
                    },
                    {
                      id: "UnPaid",
                      name: "UnPaid",
                    },
                  ]}
                  onChange={(e) => handleFilterChange("status", e)}
                  value={filters.status}
                  cleanable={true}
                  searchable={false}
                  label="Select Status"
                />
              </div>

              <div className="feesinfo-installments-filters-row2-type">
                <CustomDropDown
                  data={[
                    {
                      id: "Overdue",
                      name: "Overdue",
                    },
                    {
                      id: "Upcoming",
                      name: "Upcoming",
                    },
                  ]}
                  onChange={(e) => handleFilterChange("paymentType", e)}
                  value={filters.type}
                  cleanable={true}
                  searchable={false}
                  label="Select Type"
                />
              </div>

              <div className="feesinfo-installments-filters-row2-type">
                <CustomDropDown
                  data={
                    filters.paymentType === "Overdue"
                      ? [
                          { id: "all", name: "All" },
                          { id: "yesterday", name: "Yesterday" },
                          { id: "1_week", name: "1 Week" },
                          { id: "1_month", name: "1 Month" },
                          { id: "2_months_plus", name: "2 Months +" },
                        ]
                      : [
                          { id: "today", name: "Today" },
                          { id: "tomorrow", name: "Tomorrow" },
                          { id: "1_week", name: "1 Week" },
                          { id: "1_month", name: "1 Month" },
                        ]
                  }
                  onChange={(e) => handleFilterChange("dateRange", e)}
                  value={filters.dateRange}
                  cleanable={true}
                  searchable={false}
                  label="Select DateRange"
                />
              </div>
            </div>
          </div>

          {/* Installments Table */}
          <InstallmentsTable
            installments={filteredInstallments}
            loading={loading}
            onSelectInstallment={handleInstallmentSelect}
            selectedInstallments={selectedInstallments}
            setSelectedInstallments={setSelectedInstallments}
          />

          {/* Email Section */}
          <div className="email-section">
            <textarea
              value={emailContent}
              onChange={(e) => setEmailContent(e.target.value)}
              placeholder="Enter custom email content"
            />
            <button onClick={handleSendEmails}>Send Email</button>
          </div>
        </div>
      </div>

      {/* Overdue Summary Section in Cards */}
    </div>
  );
};

export default FeesInformation;

// Function to format the due date in a readable format
const formatDueDate = (dueDate) => {
  const date = new Date(dueDate);
  return date.toLocaleDateString("en-IN", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const InstallmentsTable = ({
  installments,
  loading,
  onSelectInstallment,
  selectedInstallments,
  setSelectedInstallments,
}) => {
  const [selectAll, setSelectAll] = useState(false);

  // Handle select all checkbox
  const handleSelectAll = () => {
    if (selectAll) {
      // Uncheck all if already selected
      setSelectAll(false);
      setSelectedInstallments([]); // Clear all selected installments
    } else {
      // Select all
      setSelectAll(true);
      setSelectedInstallments(installments.map((inst) => inst.id)); // Add all installment IDs to the selectedInstallments state
    }
  };

  // Handle individual checkbox selection
  const handleIndividualSelect = (id) => {
    onSelectInstallment(id);
  };

  // Calculate total installments count and total amount
  const totalInstallments = installments.length;
  const totalAmount = installments.reduce(
    (acc, installment) => acc + Number(installment.amount),
    0
  );

  if (loading) return <div>Loading...</div>;

  if (installments.length === 0) return <div>No installments available.</div>;

  return (
    <div className="installments-table-wrapper">
      <table className="installments-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </th>
            <th>Roll No</th>
            <th>Name</th>
            <th>Whatsapp</th>
            <th>Installment Name</th>
            <th>Amount</th>
            <th>Due Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {installments.map((inst) => (
            <tr key={inst.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedInstallments.includes(inst.id)}
                  onChange={() => handleIndividualSelect(inst.id)}
                />
              </td>
              <td>{inst.student_roll_no}</td>
              <td>{inst.name}</td>
              <td>{inst.whatsapp}</td>
              <td>{inst.installment_name}</td>
              <td>Rs. {inst.amount}</td>
              <td>{formatDueDate(inst.due_date)}</td>
              <td>{inst.status}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Display Total Count and Sum at the Bottom */}
      <div className="installments-summary">
        <p>
          <strong>Total Installments:</strong> {totalInstallments}
        </p>
        <p>
          <strong>Total Amount:</strong> Rs. {totalAmount.toFixed(2)}
        </p>
      </div>
    </div>
  );
};
